@use "@carbon/react";
@use "@carbon/react/scss/breakpoint" as *;
@import "~bootstrap/scss/bootstrap";

:root {
  --cds-layer-active:  white;
  --cds-layer-accent-active:  white;
  --bs-primary : rgb(247,135,15);
  --bs-secondary : rgb(91,89,89);
}

.cds--combo-box {
  border-radius: 5px 5px;
  border: 1px solid rgb(91,89,89,0.5);
}

.cds--dropdown.cds--dropdown--light.cds--dropdown--lg.cds--list-box.cds--list-box--lg.cds--list-box--light {
  border-radius: 5px 5px;
  border: 1px solid rgb(91,89,89,0.5);
}

.cds--dropdown {
  border-radius: 5px 5px;
  border: 1px solid rgb(91,89,89,0.5);
}

.cds--multi-select {
  border-radius: 5px 5px;
  border: 1px solid rgb(91,89,89,0.5);
}

.cds--list-box {
  border-radius: 5px 5px;
  border: 1px solid rgb(91,89,89,0.5);
}

.cds--list-box__menu {
  padding-left:0px;
}

.cds--text-input {
  border-radius:5px 5px;
  border: 1px solid rgb(91,89,89,0.5);

  &:focus {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: 2px solid var(--bs-primary);
  }
  &:active {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: 2px solid var(--bs-primary)
  }
}

.cds--side-nav__link {
  outline: 0px;
  border:0px;
  &:active {
    border: 0px;
    outline: 0px;
  }
  &:focus-visible {
    border: 0px;
    outline: 0px;
  }
}

.sidenav-item {
  outline: 0px;
  border:0px;
  &:active {
    border: 0px;
    outline: 0px;
  }
  &:focus-visible {
    border: 0px;
    outline: 0px;
  }
}

// Trim Fonts

@font-face {
  font-family: 'Trim-Light';
  src: local('Trim-Light'), url('./fonts/Trim-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-Bold';
  src: local('Trim-Bold'), url('./fonts/Trim-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-BoldItalic';
  src: local('Trim-BoldItalic'), url('./fonts/Trim-BoldItalic.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-LightItalic';
  src: local('Trim-Lightitalic'), url('./fonts/Trim-LightItalic.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-Regular';
  src: local('Trim-Regular'), url('./fonts/Trim-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-MediumItalic';
  src: local('Trim-MediumItalic'), url('./fonts/Trim-MediumItalic.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-ExtraBold';
  src: local('Trim-ExtraBold'), url('./fonts/Trim-ExtraBold.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-ExtraBoldItalic';
  src: local('Trim-ExtraBoldItalic'), url('./fonts/Trim-ExtraBoldItalic.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-SemiBold';
  src: local('Trim-SemiBold'), url('./fonts/Trim-SemiBold.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-SemiBoldItalic';
  src: local('Trim-SemiBoldItalic'), url('./fonts/Trim-SemiBoldItalic.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-Thin';
  src: local('Trim-Thin'), url('./fonts/Trim-Thin.otf') format('opentype');
}
@font-face {
  font-family: 'Trim-ThinItalic';
  src: local('Trim-ThinItalic'), url('./fonts/Trim-ThinItalic.otf') format('opentype');
}

@media (min-width: 42rem) {
  .cds--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.cds--content {
  margin-top: 3rem;
  padding: 0;
  background: var(--cds-background);
}

.cds--content .cds--css-grid {
  max-width: 100%;
  @include breakpoint(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.cds--btn--primary {
  background-color: var(--bs-primary);
  border:0px;
  padding-bottom:9px;
  border-radius: 25px;
  max-width:250px;
  text-align:center;
  margin:auto;
  max-height: 55px;
  font-family: Trim-SemiBold;

  &:hover {
    background-color: rgb(247,135,15,0.9);
    border: 1px solid rgb(247,135,15,0.9);
    color: white;
  }

  &:active {
    background-color: rgb(247,135,15,0.8); 
    border: 1px solid rgb(247,135,15,0.8);
  }

  &:focus {
    border: 1px solid var(--bs-primary);
    box-shadow: none;
  }
}

.cds--btn {
  background-color:var(--bs-primary);
  border:0px;
  padding-bottom:9px;
  border-radius: 25px;
  max-width:250px;
  text-align:center;
  margin:auto;
  max-height: 55px;
  font-family: Trim-SemiBold;

  &:hover {
    background-color: rgb(247,135,15,0.9);
    border: 1px solid rgb(247,135,15,0.9);
    color: white;
  }

  &:active {
    background-color: rgb(247,135,15,0.8); 
    border: 1px solid rgb(247,135,15,0.8);
  }

  &:focus {
    border: 1px solid var(--bs-primary);
    box-shadow: none;
  }
}



.cds--modal {
  font-family: Trim-Regular;
}


.cds--list-box {
  &:hover {
    background-color:white;
  }
}

.cds--list-box__menu {
  z-index: 5000;
  &:focus {
    outline: 1px solid var(--bs-primary);
  }
}

.cds--list-box__menu-item {
  background-color: white;
}


.cds--skeleton__placeholder {
  background-color:rgb(248,248,248);
  opacity:0.5;
}

.cds--skeleton__placeholder::before {
  background-color:rgb(247,135,15,0.3);
  opacity:0.1;
}

.page-title {
  color:white;
  background-color:var(--bs-primary);
  margin:auto;
  padding:12px 48px;
  border-radius:10px;
  margin-top:12px;
  margin-bottom:0px;
  text-align:center;
}


.page {
  display: flex;
  flex-direction: column;
  background-color: rgb(248,248,248);
  max-width: 100vw;
  min-height: calc(100vh - 143px);
  background-size: 100vw 100vh;
  font-family: 'Trim-Regular';
}

.white-page {
  background-color:white;
}

@media (max-width: 600px) {
  .page {
    .footer-terms {
      font-size: 12px;
    }
  }
}
