.cds--tag--high-contrast {
  background-color:rgb(225,225,225);
  color:black;
}

.cds--modal-container {
  z-index:1677000;
  border-radius:25px;
  background-color:rgb(230,230,230);
  padding-bottom:18px;
  min-width:50vw;
}
.cds--modal-content {
  z-index:1677000;
  padding:32px;
}

.cds--modal-content .cds--modal-scroll-content {
  z-index:1677000;
  padding:32px;
  padding-bottom:12px;
}

.cds--popover-content {
  border-radius:5px;
  background-color:white;
  color: var(--bs-primary);
  border:1px solid var(--bs-primary);
}
.cds--tooltip-content {
  border-radius:5px;
  background-color:white;
  color: var(--bs-primary);
  border:1px solid var(--bs-primary);
}
.cds--popover-caret {
  color:var(--bs-primary);
  background-color:white;
}


.cds--modal-footer > .cds--btn--primary {
  margin-right:32px;
  max-width:230px;
  text-align:center;
  padding-right:0px;
  padding-left:0px;
  justify-content:center;
  align-items:center;
  padding-bottom:0px;
  padding-top:0px;
  font-size:16px;
}

.cds--modal {
  z-index:1677000;
}

.cds--modal-body {
  z-index:1677000;
}

.cds--modal-header__heading {
  color: var(--bs-primary);
  font-family: Trim-SemiBold;
}

.cds--multi-select__wrapper{
  text-align: left;
}

#downshift-35-label {
  text-align: left;
}

.cds--dropdown__wrapper{
  text-align: left;

}

#downshift-133-label {
  text-align: left;
}

/*.cds--list-box {

  &:focus {
    //box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    border: 2px solid var(--bs-primary);
  }
  &:active {
    //box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 2px solid var(--bs-primary)
  }
}*/
/*cds--multi-select--selected cds--list-box cds--list-box--lg cds--list-box--light*/

.jobs-content {
    margin-top:75px;
    //margin-left:250px;
    min-height: calc(100vh - 130px);
    text-align: center;
}
@media screen and (max-width:1000px) {
  .jobs-content {
      min-height: calc(100vh - 170px);
  }
}
@media screen and (max-width:680px) {
  .jobs-content {
      min-height: calc(100vh - 240px);
  }
}

.recos-blur {
  margin:auto;
  margin-top:48px;
  width:88%;

}
.recos {
  display:flex,;
  background-color:white;
  margin-right:0px;
  padding-bottom:12px;
  padding-left: 25px;
  padding-right: 25px;
  overflow-x:auto;
  position:relative;
}

  .gradient-right {
    content: "";
    position: absolute;
    z-index: 1;
    right: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
    width: 40px;
    height: 96%;
  }

  .gradient-left {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    background-image: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
    width: 40px;
    height: 96%;
  }

.reco {
    min-width:400px;
    max-width:400px;
    border: 1px solid rgb(248,248,248);
    background-color:rgb(248,248,248);
    border-radius: 25px;
    padding:24px;
    //margin:auto;
    position:relative;
    padding-bottom:100px;
    text-align: left;
    margin-right: 15px;
    margin-left:15px;
    &:hover {
      cursor:pointer;
      //box-shadow:0px 1px 5px #e0e0e0;
      animation-name: reco-box-shadow;
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
    }
}

@keyframes reco-box-shadow {
    from {
      box-shadow: 0px 0px 0px #e0e0e0;
    }
    to {
      box-shadow: 0px 5px 15px #e0e0e0;
      //border:1px solid rgb(91,89,89,0.1);
    }
}

@keyframes job-mouse-leave {
  from {
    box-shadow: 0px 4px 8px #e0e0e0;
  }
  to {
    box-shadow: 0px 0px 0px #e0e0e0;
    //border:1px solid rgb(91,89,89,0.1);
  }
}

.job {
  width:100%;

  border: 1px solid rgb(248,248,248);
  background-color:rgb(248,248,248);
  border-radius: 15px;
  padding:24px;
  position:relative;
  text-align: left;
  max-width:530px;
  box-shadow: 0px 0px 0px #e0e0e0;

  transition: box-shadow cubic-bezier(1, 0, 0, 1) 1s;


  &:hover {
    cursor:pointer;
    box-shadow: 0px 4px 8px #e0e0e0;
    transition: box-shadow cubic-bezier(0, 1, 0, 1) 0.5s;
  }
}

.apply-btn {
    background-color:white;//transparent;//white;
    color: var(--bs-secondary);
    margin-top: 24px;
    margin-bottom: 4px;
    position:absolute;
    right:28px;
    border-radius:25px;
    font-family:'Trim-SemiBold';
    font-size:16px;
    //box-shadow: 1px 1px 2px #e0e0e0;
    border:1px solid var(--bs-primary);
    padding-left:20px;
    padding-right:16px;
    
    &:hover {
      background-color: rgb(91,89,89,0.1); 
      //color: white;
      transition: all cubic-bezier(0.215, 0.610, 0.355, 1) 0.4s;
    }
  
    &:active {
      background-color: rgb(91,89,89,0.4); 
    }
  
    &:focus {
      box-shadow: none;
    }
}

.job-title {
  max-width:450px;
  margin-top:32px;
  margin-bottom:18px;
  font-family: 'Trim-Bold';
  height:58px;
  display:flex;
  justify-content: center;
  align-items: center;
}


.jobs-spinner-container {
  position:relative;
  top:100px;
  height: 300px;
}

.spinner-resume {
  height:180px;
  position:relative;
  top:0px;
}

.resume-upload-container {
  position:relative;
  height: 300px;
}

.job-filtering {
  
}


.job-filters{
  display:flex;
  flex-wrap: wrap;
  background-color:rgb(248,248,248);
  padding:0px;
  max-width:85%;
  margin:auto;
  margin-bottom:12px;
  padding-bottom:0px;
  margin-top:0px;
  padding-top:0px;
  gap:12px;
}

@media screen and (max-width: 680px){
  .job-filters {
    max-width: 90%;
  }
  .jobs-content {
    margin-top:0px;
  }
}

.job-filtering-input {
  margin:0px;
  flex: 1 160px;
  margin-bottom:8px;
  margin-right:4px;
}


.all-jobs {
    margin-bottom:50px;
}


.ti-jobs {
  margin-top:5px;
}

.job-search-clear-filters-tag {
  margin-left:12px;
  position:relative;
  top:-4px;
  outline: 0px;
    border:0px;

  &:active {
    outline: 0px;
    border:0px;
  }
  &:focus {
  outline: 0px;
  border:0px;
  
  }

}
.job-search-clear-filters {

}

.job-search-submit {
  height: 48px;
  width:120px;
  padding-right:15px;
  background-color: white;
  color: rgb(89,89,91);
  border: 1.5px solid rgb(89,89,91,0.9);
  font-family:Trim-SemiBold;
  border-radius:25px;
  margin-left:8px;
  margin-right:8px;
  padding-left:20px;

  &:hover {
    background-color: rgb(89,89,91,0.2);
    //color:white;
    border: 1.5px solid rgb(89,89,91);
    transition: all cubic-bezier(0.215, 0.610, 0.355, 1) 0.4s;
  }

  &:active {
    background-color:rgb(89,89,91);
    color:white;
    border: 1.5px solid rgb(89,89,91);
  }
}


.open-jobs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));//
  grid-auto-rows: minmax(255px, auto);
  gap: 24px;
  grid-column:1;
  grid-row: 1;
  margin-top:48px;
  min-width:100%;
  padding: 24px 7.5%;
  margin:auto;
  background-color:white;
}
