.signup-form {
    display: flex;
    justify-content: start;
}
@media screen and (max-width: 1300px) {
    .signup-form {
        justify-content: center;
    }
}

.signup-btn {
    width: 140px;
    margin-right: 18px;
    background-color:white;
    color: var(--bs-primary);
    border-radius: 25px;
    height:50px;
    //padding-left:24px;
    font-family: Trim-SemiBold;
    //padding-right: 18px;
    border: 1px solid var(--bs-primary);
    margin-top:18px;
    //padding-bottom:10px;
    justify-content:center;
    
    &:hover {
        transition: cubic-bezier(0.215, 0.610, 0.355, 1) 0.3s  ;
        background-color: rgb(247,135,15,0.7);
        border: 1px solid var(--bs-primary); 
        color: white;
    }

    &:active {
        background-color: rgb(247,135,15,0.9); 
        border: 1px solid var(--bs-primary); 
        color: white;
    }

    &:focus {
        background-color: rgb(247,135,15,0.9); 
        border: 1px solid var(--bs-primary); 
        color: white;
    }

}


.login-switch {

}


.cds--content-switcher--selected {

}