@keyframes hover-menu {
    from {
        min-width:80px;
        width:80px;
    }
    to {
        min-width:185px;
        width:185px;
    }
}

.menu {
    display:none;
    padding-top:20px;
    min-width:80px;
    width:80px;
    overflow: hidden;
    position:fixed;
    padding-bottom:20px;
    border-radius: 0px 25px 25px 0px;;
    background-color: var(--bs-primary);
    color: rgba(91, 89, 89);
    font-size:14px;
    margin-top:115px;
    z-index: 300;
    box-shadow: 1px 1px 5px rgb(91,89,89,0.3);
    &:hover {
        animation-name: hover-menu;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
}

@media screen and (max-width: 1600px) {
    .menu {
        display: none;
    }
}


.menu-link {
    text-decoration: none;
    color: rgba(91, 89, 89);
    font-weight:bold;
    color: white;// #f4f4f4;
    //color:black;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 20px;
    padding-left: 12px;
    font-family: 'Trim';
   // min-width:150px;
   // width:150px;

}

.menu-link:hover {
    text-decoration: none;
    color: white;
    //font-weight:800;
    //font-size: 1.05rem;
    //line-height:0.95rem;
}




.menu-item {
    margin-right:8px;
    margin-left:8px;
    margin-top:1px;
    margin-bottom:1px;
    padding-top: 13px;
    padding-bottom: 12px;
    align-items: middle;
    border-radius: 7px;
    min-width:145px;
    width:145px;
    color:rgb(248,248,248); 
    font-family:'Trim-Bold';

    &:hover {
        background-color:rgb(255,255,255,0.3);
    }

    &:active {
        background-color:rgb(255,255,255,0.4);

    }

}

.active-menu-link {
    background-color:rgb(248,248,248);
    color:var(--bs-primary);
    font-weight:800;
    font-family:'Trim-BoldItalic';



    &:hover {
        background-color:rgb(248,248,248);
        color:var(--bs-primary);
    }
}

.active-link {
    color: var(--bs-primary);
    &:hover {
        background-color:rgb(248,248,248);
        color:var(--bs-primary);
    }
}