@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");


.resources {
    margin:auto;
    margin-top:75px;
    padding-bottom:48px;
    min-height: calc(100vh - 155px);
    width: calc(100vw);
    text-align: center;
    border:"2px solid red";
    background-color: rgb(240,240,240);
}

.cds--popover-content {
    border-radius:10px;
    background-color:white;
    color: var(--bs-primary);
    border:1px solid var(--bs-primary);
  }
  .cds--tooltip-content {
    border-radius:10px;
    background-color:white;
    color: var(--bs-primary);
    border:1px solid var(--bs-primary);
  }
  .cds--popover-caret {
    color:var(--bs-primary);
    background-color:white;
  }
  

.resource-title {
    max-width:750px;
    margin-top:48px;
    margin-bottom:48px;
    font-family: 'Trim-Bold';

}

.plex {
    font-family: 'IBM Plex Sans';
}

.resource-stacks {
    display: flex;
    flex-wrap: wrap;
    //width:94%;
    //max-width:1400px;
    margin:auto;
    justify-content: space-evenly;
}


.resource-class {
    width:50%;
    flex: 320px;
    background-color: rgb(240,240,240);
    margin:0% 1.5%;
    border-radius: 25px;
    flex-wrap: wrap;
    padding:0px;
}


.resource-class-header {
    padding: 4px 16px;
    //background-color: var(--bs-primary);
    color: white;
    border:2px solid var(--bs-primary);
    border-radius: 15px;
    font-family: Trim-Bold;
    width:100%;
    margin: auto;
    margin-top:0px;
    margin-bottom: 24px;
    font-size: 28px;//23px;
    background-color:white;
    
}

@keyframes resource-hover {
    from {
        box-shadow: 0px 0px 0px #e0e0e0;//var(--bs-secondary);
    }
    to {
        box-shadow: 0px 5px 20px #e0e0e0;// var(--bs-secondary);
    }
}

@keyframes resource-mouse-leave {
    from {
        box-shadow: 0px 5px 20px #e0e0e0;//var(--bs-secondary);
    }
    to {
        box-shadow: 0px 0px 0px #e0e0e0;// var(--bs-secondary);
    }
}


.resource {
    border-radius:20px;
    padding:32px 36px 32px 32px;
    padding-bottom:20px;
    line-height:27px;
    color: black;
    text-decoration: none;
    text-align: left;
    margin: 32px 0px;
    margin-top:0px;
    background-color:white;
    //border: 1px solid rgb(91,89,89,0.15);
    animation-name: resource-mouse-leave;
    animation-duration:0.2s;
    animation-fill-mode: forwards;

    &:hover {
        animation-name: resource-hover;
        animation-duration:0.3s;
        animation-fill-mode: forwards;
        //box-shadow: 0px 1px 10px var(--bs-secondary);// #e0e0e0;
        cursor:pointer;
    }
}

@media screen and (max-width: 1200px) {
    .resource {
        font-size: 14px;
        line-height:24px;
    }
}

@media screen and (max-width: 680px) {
    .resources {
        margin-top:0px;
    }
    .resource {
        padding:24px 24px;
    }
    .resource-stacks{
       width:96%;
    }
}

.resource-name {
    text-decoration: none;
    color:var(--bs-primary);
    font-family: Trim-Bold;
    font-weight:800;
    min-height:24px;
    &:hover {
        color: rgb(247,135,15,0.9);
    }
    margin-bottom:15px;
}

.resource-name-template {
    text-decoration: none;
    color:rgb(89,89,91);//var(--bs-primary);
    padding-left:4px;
    font-family: Trim-Regular;//Trim-SemiBold;
    font-size:16px;
    font-weight:800;
    min-height:24px;
    margin-bottom:15px;
}


.tooltip-trigger {
    background-color: white;
    border: 0px;
}


.resource-link {
    color: #0f7aec;
    font-size: 16px;
    margin-top:15px;
    font-family: Trim-SemiBold;
}