.header {
  display: flex;
  min-height: 75px;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: white;
  border-bottom: 1px solid rgb(248,248,248);
  z-index:10000;
  box-shadow: 0px 1px 5px rgb(91,89,89,0.3);
}

.header-logo-container {
    margin-left: 22.5px;
    min-width:220px;
    max-width:220px;
    margin-right:10px;
  
    .header-logo {
      width: 100%;
    }
  
    .mobile-logo {
      display: none;
    }
}
.header-logo-container-clickable {
  &:hover {
    cursor: pointer;
  }
}


.navigation {
  display:flex;
  min-width:300px;
  justify-content: space-between;
  align-items: center;
  padding:0px 24px;
  font-family: Trim-SemiBold;
  color: var(--bs-secondary);
}


.narrow-menu {
  top:0px;
  height:68px;
  display:flex;
  font-family: Trim-SemiBold;
  position:sticky;
  background-color:white;
  min-width:100%;
  z-index:9000;
  padding:16px;
  box-shadow:0px 1px 5px rgb(91,89,89,0.3);
  border-bottom:1px solid #e0e0e0;
  align-items:center;
}


.nav-item {
  border-radius:25px;
  padding: 12px 20px;
  color: var(--bs-secondary);
  font-family: Trim-SemiBold;
  &:hover {
    background: rgb(240,240,240);
    cursor:pointer;
  }
  &:active {
    background: rgb(225,225,225);
    cursor:pointer;
  }
}

@media screen and (max-width:1600px){
  .navigation {
    display:flex;
  }
}

.sidenav-item {
  //margin-bottom:5px;
  height:37px;
  margin-left:8px;
  margin-right: 8px;
  border-radius:5px;
  z-index:35000;
  &:hover {
    cursor: pointer;
    background-color: rgb(91,89,89,0.1);
  }
}

@keyframes gradient-show {
  from {
    opacity:0%;
  }
  to {
    opacity:100%;
  }
}

.upper-menu {
  padding-bottom:4px;
  max-width: 200px;
  animation-name: gradient-show;
  animation-duration: 0.6s;
  max-height:234px;
  margin-top:18px;
  margin-right:12px;
  border-radius:20px;
  background-color:white;
  border:2px solid var(--bs-primary);
  box-shadow: 0px 1px 5px rgb(91,89,89,0.3);
  z-index:35000;
}

.upper-menu-checkin {
  max-height:70px;
  max-width: 160px;
}

.upper-btn {
  border: 0px;
  outline: 0px;
  &:hover {
    //background-color:var(--bs-primary);
  }
  &:active {
    //background-color:yellow;
  }
}
/*.narrow-w-menu-item {
  display:none;
  max-height:0px;
  padding:0px;
}*/
  

  @media screen and (max-width:680px){
    .navigation {
      display:none;
    }
    .upper-menu {
      //max-height:348px;
    }

    .header {
      position:relative;
      box-shadow: none;
      border-bottom: 1px solid rgb(91,89,89,0.3);
    }
    .logged-out-checkin {
      box-shadow: 0px 1px 5px rgb(91,89,89,0.3);
      border-bottom: 1px solid rgb(91,89,89,0.3);
    }
  }

.profile-name {
  font-size: 16px;
  font-family: Trim-Bold;
  display:flex;
  justify-content:start;
  align-items: center;
  max-width:200px;
  margin: 0px;
  margin-left: 16px;
  position:relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  min-height:32px;
}

.initials {
  border-radius: 50%;
  height:44px;
  width:44px;
  line-height:18px;
  margin-right:10px;
  font-family:Trim-SemiBold;
  align-items:center;
  font-size: 18px;
  border:1.5px solid var(--bs-primary);
  background-color:white;
  color:var(--bs-primary);
  display:flex;
  justify-content: center;
}

.upper-right-menu-item {
  font-family: Trim-Regular;
}