
.footer {
    font-family: "Trim-Regular";
    display: flex;
    min-height: 55px;
    max-width: 100vw;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 0;
    border-top: 1px solid rgb(248,248,248);
    padding-bottom:12px;
    z-index:1000;
    margin-bottom:0px;

  }

  @media screen and (max-width: 1000px) {
    .footer {
      display: block;
      padding: 16px 24px;      
    }
  }

  .footer-title {
    font: normal 400 14px "Trim-Regular";
    font-weight: bold;
    color: white;
    margin: auto;
  }

  .footer-terms {
    font: normal 300 12px "Trim-Regular";
    text-decoration: none;
    color: #4D5358;
    margin: auto;
    margin-bottom:8px;
    &:hover {
      text-decoration: none;
      color: black;
    }
  }


  .footer-copyright {
    font: normal 300 12px "Trim-Regular";
    text-decoration: none;
    color: #4D5358;
    margin: auto;
    margin-bottom:8px;
  }