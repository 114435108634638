
// profile component styles

.profile-content {
  margin: auto;
  margin-top: 75px;
  min-width: calc(100vw - 50px);
  min-height: calc(100vh - 130px);
}

.profile-c {
  margin-top: 18px;
}

.profile-spinner {
  margin-left: calc(50% - 35px);
  margin-top: 180px;
}

.profile-spinner-container {
  justify-content: center;
}

.card-title {
  color: var(--bs-secondary);
  border-radius: 25px 25px 0px 0px;
  border-bottom: 0;
  text-align: center;
  margin: auto;
  max-width: 1000px;
  width: 90%;
  margin-bottom: 0;
  line-height: 40px;
  box-shadow: 0px 0px 5px #e0e0e0;
  padding: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
  border: 1px solid rgb(91, 89, 89, 0.2);
  background-color: rgb(91, 89, 89, 0.2);
  border-bottom: 0px;
}

.profile-card-title {
  border-radius: 10px;
  color: var(--bs-primary); 
  background-color: white;
  font-family: "Trim-SemiBold";
  font-size: 22px;
  margin: auto;
  padding: 12px 48px;
  border-radius: 12px;
  margin-bottom: 0px;
  text-align: center;
}

@media screen and (max-width: 1300px) {
  .employ-checkin-title {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .employ-checkin-title {
    font-size: 18px;
    padding-left: 18px;
    padding-right: 18px;
  }

  .demog-checkin-title {
    font-size: 18px;
  }

  .profile-c {
    margin-top: 12px;
  }

  .profile-content {
    min-height: calc(100vh - 180px);
  }
}

.help-us-help-you {
  font-size: 14px;
  font-family: Trim-Regular;
  border: 1px solid rgb(200, 200, 200);
  padding: 12px 15px 15px;
  border-radius: 15px;
  text-align: center;
  color: var(--bs-secondary);
  background-color: white;
  line-height: 20px;
  margin: auto;
  width: 90%;
  max-width: 1000px;
  margin-top: 24px;
}

.info {
  background-color: rgb(245, 245, 245);
  background-size: cover;
  position: relative;
  width: 90%;
  display: flex;
  gap: 12px;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid rgb(91, 89, 89, 0.2);
  border-top: 0px;
  padding: 12px 32px 25px;
  margin: auto;
  border-radius: 0px 0px 25px 25px;
  max-width: 1000px;
  box-shadow: 0px 1px 5px #e0e0e0;
}

.info2 {
  margin-bottom: 48px;
}

.info-checkin1 {
  margin-bottom: 24px;
  min-height: 375px;
}

@media screen and (max-width: 680px) {
  .profile-content-no-checkin {
    margin-top: 0px;
    position: relative;
  }
  .profile-content-checkin {
    margin-top: 0px;
  }
}

.next-btn-profile {
  border: 1px solid var(--bs-primary);
  background-color: white;
  color: var(--bs-secondary);
  font-family: Trim-SemiBold;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 12px;
  position: relative;
  height: 50px;
  margin-right: 8px;

  &:hover {
    background-color: rgb(91, 89, 89, 0.05);
    outline-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
  }

  &:active {
    background-color: rgb(91, 89, 89, 0.2);
    outline-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
  }

  &:focus {
    outline: 1px solid var(--bs-primary);
    border: 1px solid var(--bs-primary);
    box-shadow: none;
  }
}

.next-employ {
  margin-top: 12px;
}

.employ-profile-title {
  position: relative;
}

.demog-profile-title {
  position: relative;
}

.back-btn {
  border: 1px solid var(--bs-primary);
  background-color: white;
  color: var(--bs-secondary);
  font-family: Trim-SemiBold;
  border-radius: 25px;
  box-shadow: 1px 1px 2px #e0e0e0;
  padding-left: 12px;
  padding-right: 24px;
  height: 50px;
  min-height: 50px;

  &:hover {
    background-color: rgb(91, 89, 89, 0.05);
    outline-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
  }

  &:active {
    background-color: rgb(91, 89, 89, 0.2);
    outline-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
  }

  &:focus {
    outline: 1px solid var(--bs-primary);
    border: 1px solid var(--bs-primary);
    box-shadow: none;
  }
}

h4 {
  font-weight: bold;
  padding-left: 40px;
}

.dd {
  font-family: Trim-Regular;
  width: 100%;
}

.ti {
  margin-top: 6px;
  font-family: Trim-Regular;
}

.skel {
  height: 40px;
  margin-bottom: 40px;
}

.credits {
  width: 120px;
  margin-left: 2px;
  position: relative;
}

.edu-year {
  width: calc(100% - 122px);
}

.half {
  width: 50%;
}

.right {
  margin-left: 2px;
}

.other {
  width: 50%;
}

.loading {
  color: #ffc768;
  background-color: #ffc768;
}

.date {
  margin-top: 5px;
}

.checkin-vertical-offset {
  position: relative;
}

.other-event-interest-checkin {
  position: relative;
  top: -1px;
}

.other-event-interest-profile {
  position: relative;
  top: -1px;
}

.resume {
  margin-top: 30px;
  padding: 8px 20px;
  background-color: white;
  cursor: pointer;
  border: 1px solid rgb(91, 89, 89, 0.5);
  margin-right: 12px;
  font-size: 14px;
  height: 39px;
}

.file-uploader-submit {
  margin-top: 28px;
  padding: 8px 42px 8px 32px;
  background-color: white;
  cursor: pointer;
  border: 1px solid black;

  &:hover {
    padding: 8px 42px 8px 32px;
    background-color: white;
    cursor: pointer;
    border: 1px solid black;
  }
}

.o-source {
  position: relative;
  top: -1px;
  min-width: 95px;
}
.o-company {
  position: relative;
  top: -1px;
}

.profile-employ-col {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  flex: 1 220px;
}

.profile-demog-col {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 100%;
  margin-bottom: 8px;
  flex: 1 130px;
}

.resume-update-req {
  margin-top: 3px;
  font-size: 12px;
}

// Carbon style overwrite

.cds--tag--high-contrast {
  background-color: rgb(225, 225, 225);
  color: black;
}

.cds--combo-box {
  border-radius: 5px 5px;
  border: 1px solid rgb(91, 89, 89, 0.5);
}

.cds--dropdown.cds--dropdown--light.cds--dropdown--lg.cds--list-box.cds--list-box--lg.cds--list-box--light {
  border-radius: 5px 5px;
  border: 1px solid rgb(91, 89, 89, 0.5);
}

.cds--dropdown {
  border-radius: 5px 5px;
  border: 1px solid rgb(91, 89, 89, 0.5);
}

.cds--multi-select {
  border-radius: 5px 5px;
  border: 1px solid rgb(91, 89, 89, 0.5);
}

.cds--list-box {
  border-radius: 5px 5px;
  border: 1px solid rgb(91, 89, 89, 0.5);
}

.cds--list-box-light {
  border-radius: 5px 5px;
  border: 1px solid rgb(91, 89, 89, 0.5);
}

.cds--list-box__menu {
  padding-left: 0px;
}

.cds--text-input {
  border-radius: 5px 5px;
  border: 1px solid rgb(91, 89, 89, 0.5);

  &:focus {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: 2px solid var(--bs-primary);
  }
  &:active {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: 2px solid var(--bs-primary);
  }
}
