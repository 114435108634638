

.login-page{
    max-width:1300px;
    margin:auto;
    margin-top: 55px;
    display: flex;
    width: 100vw;
    align-items: flex-start;
  }


.description {
  font: normal 400 18px "Trim-Regular";
  margin-bottom: 12px;
  line-height:28px;
}

.login-btn {
  background-color: #f7870f;
  color: white;
  border-radius: 25px;
  height:50px;
  width: 140px;
  font-family: Trim-SemiBold;
  border: 1px solid var(--bs-primary);
  margin-top:18px;
  
  &:hover {
    background-color: rgb(247,135,15,0.8);
    border: 1px solid var(--bs-primary); 
  }

  &:active {
    background-color: rgb(247,135,15,0.7); 
    border: 1px solid var(--bs-primary); 
  }

  &:focus {
    border: 1px solid var(--bs-primary); 

  }
}

.image-container {
  margin-top:0px;
  max-width:880px;//580px;
  min-width:780px;//580px;
}

.value-prop {
  margin-bottom: 24px;
  width: 50%;
  margin-top:120px;
  margin-left:18px;

  .title {
    font: normal 600 32px "Trim-Regular";
    margin-bottom: 25px;
    line-height: 40px;
  }
}

@media screen and (max-width: 1300px) {
  .image-container {
    background-color:"red";
    padding-top:0px;
    min-width:550px;
    margin-top:0px;

  }

  .value-prop {
    width: min(85%, 820px);
    align-items: center;
    text-align: center;
    padding-top:8px;
    padding-bottom:4px;
    margin-top:0px;
    margin-left:0px;
  }

  .login-page {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 680px) {
  .image-container {
    background-color:"red";
    padding-top:0px;
    min-width:85%;
    margin-top:32px;

  }

  .value-prop {
    width: 85%;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    padding-bottom:0px;
    margin-left:0px;
    .title {
      font-size: 21px;
      margin-bottom:14px;
      line-height: 28px;
    }
  }
  .login-page {
    margin-top:0px;
  }

  .description {
    line-height:27px;
  }

}