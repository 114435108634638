.dashboard-content {
    margin:auto;
    margin-top:48px;
    //margin-left:250px;

    min-height: calc(100vh - 103px);
    min-width: calc(100vw - 250px);
}

@media screen and (max-width:680px) {
    .dashboard-content {
        margin-top:-24px;

    }
}

.welcome {
    margin-top:24px;
    text-align: center;
    margin-bottom:24px;
    color:white;
    font-weight:600;
    border-radius:10px;
    font-family: 'Trim-Bold';
    margin-left:24px;
    margin-right:24px;
}

.full-dashboard {
    margin:auto;
    width: 90%;
    min-width: 90%;
    max-width: 1200px;
    justify-content:center;
    border: 1px solid rgb(91,89,89,0.2);
    border-radius: 20px;
    margin-top: 24px;
    padding-top:12px;
    margin-bottom:40px;
    background-color:rgb(245,245,245);
    //box-shadow: 0px 1px 5px #e0e0e0;
    overflow-x:hidden;
}


.dashb {
    display:flex;
    flex-wrap: wrap;
    padding:0px 24px;
    border-radius:0px 0px 15px 15px;
    border: 1px solid rgb(248,248,248);
    justify-content: space-between;
    min-width:100%;
    gap:24px;
}

.dashboard-img {
    max-width: 100%;
    max-height: 100%;
    min-width: 800px;
    margin-right:24px;
}

@media screen and (min-width:1700px){
    .dashboard-img {
        position:relative;
    }
}

.dashboard-half {
    color:rgb(91, 89, 89);
    border-radius: 5px;
    position:relative;
    flex: 420px;
    margin: auto;
    margin-top:0px;
    padding: 8px 0px;
    padding-bottom:68px;
    font-size:18px;
    font-family: 'Trim-SemiBold';
    margin-bottom:0px;

    &:hover {
        cursor: pointer;
    }
}
.dashboard-left {
   min-height:100%;
}

.dashboard-band {
    display:flex;
    border: 1px solid rgb(91,89,89,0.3);
    padding:12px 18px;
    background-color:white;
    min-height:80px;
    vertical-align:middle;
    align-items:middle;
    align-content:middle;
    justify-content:space-between;
    overflow-x:"hidden";
    font-family: Trim-Regular;
    border-radius:7px;
    
    &:hover {
        animation-name: reco-box-shadow;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
}


@keyframes reco-box-shadow {
  from {
    box-shadow:0px;
  }
  to {
    box-shadow: 0px 2px 5px rgb(91, 89, 89, 0.9);

  }
}


.orange-band {
    border:2px solid var(--bs-primary);
    color: var(--bs-primary);
    font-family: Trim-SemiBold;
    border-radius:14px;
    font-size: 22px;
    padding: 8px;
    min-height:48px;
    margin-bottom: 12px;
    &:hover {
        animation:none;
    }
}

.home-tile-nav {
    position:absolute;
    right: 32px;
    bottom:20px;
    font-size: 12px;
    border:2px solid rgb(91, 89, 89);
    border-radius:25px;
    padding:4px 12px;
    width:150px;
    background-color: rgb(91, 89, 89);
    color:white;
}


.dashboard-spinner-container {
    display: flex;
    justify-content: center;
    height:200px;

}

.dashboard-spinner {
    margin:auto;
}