.table {
    margin-top:200px;
}

.event-content {
    margin:auto;
    margin-bottom:32px;
    margin-top:75px;
    min-height: calc(100vh - 162px);
    width:100vw;
}
@media screen and (max-width:1000px) {
    .event-content {
        min-height: calc(100vh - 213px);
    }
}
@media screen and (max-width: 680px) {
    .event-content {
        margin-top: 0px;
        min-height: calc(100vh - 280px);
    }
    .event-title {
        min-width: 97%;
    }
}

.event-spinner-container {   
    position:relative;
    top:148px;
    left:calc(50% - 35px);
    max-width:74px;
}

.event-spinner {

}

.cds--g10 .cds--layer-one {
    padding:0px;
    margin:0px;
    border:0px;

}

.cds--data-table-container {
    margin:0px;
    border:0px;

}


.cds--data-table-container {
    background-color:white;
    //border-radius:15px;
}

.cds--data-table-header {
    background-color:white;


}


.cds--data-table-content {
    border-radius:5px;
    box-shadow: 0px 1px 5px #e0e0e0;

}

.cds--data-table {
    border-radius:5px;
    box-shadow: 0px 1px 5px #e0e0e0;
}

.event-title {
    margin-top:32px;
    margin-bottom:0px;
    max-width: 420px;
    font-family: 'Trim-Bold';
    border-radius:10px;
    padding:12px 48px;
    height:58px;
    display:flex;
    justify-content: center;
    align-items: center;
}


.register-btn {
    font-size:14px;
    font-family:Trim-Regular;

    border: 3px solid var(--bs-primary);
    background-color:white;
    color: var(--bs-primary);
    font-family: Trim-Regular;
    border-radius:25px;
    font-weight:800;
    box-shadow: 1px 1px 2px #e0e0e0;
    padding-left:20px;
    padding-right:48px;
    margin-right:12px;

    &:hover {
        background-color: var(--bs-primary);
        border: 3px solid var(--bs-primary);
        color:white;
    }


    &:active {
        background-color: rgb(247,135,15,0.7);
        border: 4px solid var(--bs-primary);
        color:white;
    }

    &:focus {
        background-color: rgb(247,135,15,0.7);
        border: 4px solid var(--bs-primary);
        color:white;
    }

}


.event-tile {
    background-color: white;
    margin:auto;
    max-width:min(95vw, 900px);
    width:min(95vw, 900px);
    padding:38px 64px;
    padding-bottom:48px;
    border-radius:15px;
    box-shadow: 0px 1px 8px 3px rgb(230,230,230);
}

@media screen and (max-width: 1000px) { 
    .event-tile {
        padding:38px 64px;
    }
}

@media screen and (max-width: 700px) {
    .event-tile {
        padding:28px 28px;
        font-size: 14px;
        padding-bottom:55px;
        margin-bottom:0px;
    }
}

.event-description {
    margin-bottom:24px;
    margin-top:24px;
}

.event-access-info {
    min-width:350px;
}


.event-date {
    margin-top:32x;
    margin-bottom:8px;
}

.schedule {
    margin-bottom:8px;
}

.registration-btn {
    border: none;
    padding: 12px 18px;
    font-size: 15px;
    font-family: Trim-SemiBold;
    text-transform: uppercase;
    border-radius: 20px;
    color: white;
    background-color: var(--bs-primary);

    &:hover {
        background-color: rgb(245,135,15,0.9);
    }
    &:active {
        background-color: rgb(245,135,15,0.7);
    }
}