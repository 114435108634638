
  .account-content {
    min-height: calc(100vh - 130px);
    min-width: calc(100vw - 505px);
    margin:auto;
    margin-top:75px;
    position:relative;
    padding-left: 24px;
    padding-right: 24px;
  }

  .account-spinner-container {
    position:relative;
    border: 2px solid purple;
    min-height: 200px;
    margin:auto;
    margin-top:0px;
    padding:24px 32px;
    background-color:rgb(248,248,248);
    border: 1px solid rgb(91,89,89,0.2);
    border-top:0px;
    border-radius: 0px 0px 25px 25px;
    max-width:670px;
    box-shadow: 0px 1px 5px #e0e0e0;
    margin-bottom:48px;
    align-items:center;
    justify-content: center;
    display:flex;
    min-width:360px;
  }



@media screen and (max-width:1500px) {
  .account-content {
      min-height: calc(100vh - 130px);
  }
}
@media screen and (max-width: 1000px) {
  .account-content {
      min-height: calc(100vh - 175px);
  }
}
@media screen and (max-width: 680px) {
  .account-content {
      margin-top:-17px;
      min-height: calc(100vh - 225px);
  }
}

  .userinfo {
   margin:auto;
   background-color:rgb(248,248,248);
   border: 1px solid rgb(91,89,89,0.2);
   border-radius: 25px 25px;
   max-width:670px;
   box-shadow: 0px 1px 5px #e0e0e0;
   margin-bottom:48px;
   margin-top:48px;
   max-width:670px;
  }

  .account-title {
    max-width:670px;
    font-family: 'Trim-Bold';
    position:relative;
  }

  #f-name {
    //width:298px;
    flex:1 120px;
    margin-bottom:24px;
  }

  #l-name {
    //width:298px;
    flex:1 120px;
    margin-bottom:24px;
  }

  #email {
    max-width:298px;//98%;
  }
  .account-save-btn {
    margin-top: 16px;
    position:absolute;
    right:calc(3% + 15px);
    border: 3px solid var(--bs-primary);
    background-color:white;
    color: var(--bs-primary);
    font-family: Trim-SemiBold;
    border-radius:25px;
    font-weight:800;
    box-shadow: 1px 1px 2px #e0e0e0;
    padding-left:20px;
    padding-right:20px;

    &:hover {
      background-color: var(--bs-primary); //#f7870f;//rgb(230, 150, 0);
      outline-color:var(--bs-primary); // rgb(230, 150, 0);
      border:3px solid var(--bs-primary); // rgb(230, 150, 0);
      color: white;
    }
  
    &:active {
      background-color: rgb(247,135,15,0.8); 
      outline-color:  rgb(247,135,15,0.8); //rgb(200, 130, 0);
      border:3px solid var(--bs-primary); // rgb(230, 150, 0);
    }
  
    &:focus {
      background-color: rgb(247,135,15,0.8); 
      outline:2px solid rgb(247,135,15,0.8); 
      border:3px solid var(--bs-primary); // rgb(230, 150, 0);
      box-shadow: none;
    }

  }

.account-header {
  padding: 24px;
  padding-top:12px;
  border-radius: 25px 25px 0px 0px;
  max-width:670px;
  background-color:white;
}

.user-info-content {
  padding: 24px 32px;
}